<template>
  <div style="border: 1px solid rgba(0, 0, 0, 0.15)" class="rounded-lg">
    <div class="flex flex-col px-4 py-6 text-center">
      <div>
        <PersonIcon />
      </div>
      <div class="text-[20px] font-semibold mb-2 mt-4">Welcome back {{ fullName }}!</div>

      <div class="text-sm">
        This is your last saved progress. Would you like to continue where you left off?
      </div>

      <div class="mt-6 flex flex-col items-center">
        <GumButton size="large" type="primary" @click="emit('confirm')">
          Continue as {{ fullName }}
        </GumButton>
        <GumButton size="small" type="link" @click="resetSession" class="!px-0 mt-6 font-semibold">
          <span class="underline"> Start a new registration </span>
        </GumButton>
      </div>
    </div>

    <div class="flex items-center justify-between pt-6 actions-border mt-4"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { GumButton, PersonIcon } from '@/components/ui'
import type { FormSession } from '../data/getFormSession.gql'
import { resetSession } from '../services/session'

const props = defineProps<{
  session: FormSession
}>()

const emit = defineEmits<{
  (e: 'confirm'): void
}>()

const fullName = computed(
  () => `${props.session.submittedFirstName} ${props.session.submittedLastName}`
)
</script>
