import type { GetPublicFormQuery, GetPublicFormQueryVariables } from './getPublicForm.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPublicForm($slug: String!) {
    form(slug: $slug) {
      id
      status
      title
      plainTitle
      metaTitle
      metaDescription
      header {
        id
        url
      }

      event {
        id
      }

      closedMessageBody
      successMessageBody
      submitButtonLabel
      backgroundColor
    }
  }
`

export type Form = NonNullable<GetPublicFormQuery['form']>

export const getPublicForm = (slug: string) => {
  const { loading, result } = useQuery<GetPublicFormQuery, GetPublicFormQueryVariables>(query, {
    slug
  })

  const form = computed(() => result.value?.form)

  return { loading, form }
}
