import type {
  Conditional,
  FormItem,
  FormRow,
  FormRowColumns,
  VisibilityCondition,
  VisibilityConditionOperator
} from '@/components/form-builder/types'
import type { FormData, FormItemData } from './types'

export const applyVisibility = <T extends FormRow | FormItem | FormRowColumns>(
  items: T[],
  data: FormData
): T[] => {
  const final: T[] = []

  for (const item of items) {
    if (!shouldBeVisible(item, data)) {
      continue // The whole row is not visible
    }

    if (item.kind === 'columns') {
      const children = applyVisibility(item.children, data)

      if (!children.length) continue // The whole column is not visible

      final.push({ ...item, children })
      continue
    }

    if (item.kind === 'group') {
      const children = applyVisibility(item.children, data)

      if (!children.length) continue // The whole group is not visible

      final.push({ ...item, children })
      continue
    }

    final.push({ ...item })
  }

  return final
}

const shouldBeVisible = (row: FormRow | FormItem, data: FormData): boolean => {
  if (!('alwaysVisible' in row)) {
    return true // It's not conditionable so it's always visible
  }

  const conditions = validConditions(row)
  if (!conditions.length) {
    return true
  }

  const arrayFunction = row.visibilityConditions.operand === 'and' ? 'every' : 'some'

  return conditions[arrayFunction]((condition) =>
    match(condition.value, data[condition.targetItemId], condition.operator)
  )
}

const match = (
  conditionValue: FormItemData,
  realValue: FormItemData,
  operator: VisibilityConditionOperator
): boolean => {
  let matches = conditionValue === realValue

  if (typeof conditionValue === 'string' && realValue) {
    // Match strings case-insensitive
    matches = conditionValue.toLowerCase().trim() === realValue.toString().toLowerCase().trim()
  }

  if (conditionValue === false) {
    // Be a bit more permissive with false values
    matches = realValue === false || realValue === undefined
  }

  return operator === 'is' ? matches : !matches
}

const validConditions = (item: Conditional): VisibilityCondition[] => {
  if (!item.visibilityConditions) {
    return []
  }

  return item.visibilityConditions.conditions.filter((condition) => {
    return condition.targetItemId && condition.operator
  })
}
