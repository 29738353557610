<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="162" height="32" viewBox="0 0 162 32" fill="none">
    <mask
      id="mask0_119_38256"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="162"
      height="32"
    >
      <path d="M161.533 0H0V32H161.533V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_119_38256)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.133 0.477661H108.007L106.21 8.11945H104.196L102.991 2.73321L101.797 8.11945H99.7902L97.9711 0.477661H99.8672L100.953 5.7586L102.026 0.477661H103.965L105.137 5.77995L106.133 0.477661Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M114.654 4.06001C114.654 2.39244 113.69 1.52681 112.547 1.52681C111.401 1.52681 110.44 2.39244 110.44 4.06001C110.44 5.72695 111.401 6.5929 112.547 6.5929C113.69 6.5929 114.654 5.72695 114.654 4.06001ZM108.485 4.06001C108.485 1.61334 110.136 0 112.547 0C114.946 0 116.61 1.61334 116.61 4.06001C116.61 6.50605 114.946 8.1194 112.547 8.1194C110.136 8.1194 108.485 6.50605 108.485 4.06001Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.989 3.72406H120.243C121.144 3.72406 121.832 3.57692 121.832 2.85988C121.832 2.15382 121.144 1.99569 120.243 1.99569H118.989V3.72406ZM118.989 5.2421V8.11945H117.087V0.477661H120.187C122.28 0.477661 123.847 0.94135 123.847 2.83881C123.847 3.96673 123.19 4.60901 122.267 4.94662L124.256 8.11945H122.023L120.278 5.2421H118.989Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M130.946 6.52755V8.11945H125.212V0.477661H127.103V6.52755H130.946Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M133.272 1.99569V6.6014H134.042C135.406 6.6014 136.639 6.3169 136.639 4.29307C136.639 2.28049 135.406 1.99569 134.042 1.99569H133.272ZM138.593 4.29307C138.593 7.21315 136.572 8.11945 134.009 8.11945H131.425V0.477661H134.009C136.572 0.477661 138.593 1.38397 138.593 4.29307Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.184 11.9403V16.8691C104.184 18.8493 103.118 20.0597 101.079 20.0597C99.0247 20.0597 98.0266 18.7285 97.9711 16.8691H99.8497C99.8803 18.1452 100.276 18.5638 101.079 18.5638C101.867 18.5638 102.306 18.1452 102.306 16.8691V11.9403H104.184Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M111.352 12.418V13.9989H107.048V15.3377H111.117V16.9289H107.048V18.4679H111.352V20.0598H105.14V12.418H111.352Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M119.992 12.418H121.866L120.069 20.0598H118.053L116.847 14.6734L115.654 20.0598H113.649L111.83 12.418H113.727L114.812 17.6987L115.885 12.418H117.821L118.996 17.7198L119.992 12.418Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M122.344 20.0598H124.256V12.418H122.344V20.0598Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.204 14.1813C127.204 14.571 127.561 14.8529 128.083 14.9717L129.122 15.1883C130.35 15.4479 131.903 15.8379 131.903 17.483C131.903 19.1286 130.297 20.0597 128.715 20.0597C126.764 20.0597 125.483 19.0527 125.212 17.3641H126.995C127.192 18.1868 127.821 18.5658 128.756 18.5658C129.364 18.5658 130.055 18.3602 130.055 17.7106C130.055 17.202 129.468 16.9525 128.641 16.7691L127.717 16.5741C126.481 16.3032 125.36 15.6969 125.36 14.2892C125.36 12.6874 127.024 11.9403 128.514 11.9403C130.005 11.9403 131.418 12.6122 131.681 14.344H129.91C129.73 13.7593 129.175 13.4239 128.408 13.4239C127.717 13.4239 127.204 13.6941 127.204 14.1813Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M140.027 20.0598H138.238V16.9289H135.125V20.0598H133.337V12.418H135.125V15.3377H138.238V12.418H140.027V20.0598Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M102.37 32C99.9294 32 98.449 30.4416 98.449 27.9297C98.449 25.4504 99.9839 23.8806 102.424 23.8806C104.388 23.8806 105.771 25.1582 106.041 26.9443H104.227C104.023 25.8727 103.233 25.4078 102.36 25.4078C101.257 25.4078 100.406 26.3273 100.406 27.9297C100.406 29.5857 101.257 30.4735 102.382 30.4735C103.181 30.4735 104.065 30.1274 104.281 28.9367H106.096C105.813 30.7548 104.345 32 102.37 32Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.743 27.9406C112.743 26.2731 111.779 25.4078 110.636 25.4078C109.489 25.4078 108.528 26.2731 108.528 27.9406C108.528 29.6076 109.489 30.4735 110.636 30.4735C111.779 30.4735 112.743 29.6076 112.743 27.9406ZM106.573 27.9406C106.573 25.494 108.225 23.8806 110.636 23.8806C113.031 23.8806 114.698 25.494 114.698 27.9406C114.698 30.3867 113.031 32 110.636 32C108.225 32 106.573 30.3867 106.573 27.9406Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.434 26.2528V31.5224H115.654V23.8806H117.899L121.041 29.1506V23.8806H122.822V31.5224H120.577L117.434 26.2528Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M129.512 26.4681C129.35 25.9269 128.792 25.4078 127.782 25.4078C126.64 25.4078 125.75 26.2514 125.75 27.9077C125.75 29.5647 126.576 30.4735 127.816 30.4735C128.673 30.4735 129.567 30.149 129.567 29.153V29.001H127.662V27.486H131.425V31.8593H130.001L129.946 30.9933C129.435 31.6756 128.664 32 127.531 32C125.204 32 123.778 30.4199 123.778 27.9077C123.778 25.4288 125.413 23.8806 127.804 23.8806C129.555 23.8806 130.969 24.7578 131.339 26.4681H129.512Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M134.279 27.127H135.536C136.437 27.127 137.125 26.9799 137.125 26.2629C137.125 25.5568 136.437 25.3987 135.536 25.3987H134.279V27.127ZM134.279 28.6451V31.5224H132.38V23.8806H135.48C137.569 23.8806 139.136 24.3443 139.136 26.2418C139.136 27.3697 138.479 28.0123 137.56 28.3496L139.549 31.5224H137.312L135.567 28.6451H134.279Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M146.718 23.8806V25.4619H142.414V26.8004H146.482V28.392H142.414V29.9305H146.718V31.5224H140.505V23.8806H146.718Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M149.188 26.1213C149.188 26.5116 149.545 26.7932 150.067 26.912L151.106 27.1283C152.334 27.3885 153.886 27.7786 153.886 29.4233C153.886 31.0692 152.281 32 150.699 32C148.748 32 147.47 30.9933 147.196 29.3047H148.978C149.179 30.1274 149.808 30.5061 150.74 30.5061C151.348 30.5061 152.042 30.3002 152.042 29.6509C152.042 29.142 151.454 28.8928 150.625 28.7094L149.701 28.5141C148.465 28.2435 147.343 27.6372 147.343 26.2298C147.343 24.6277 149.011 23.8806 150.498 23.8806C151.989 23.8806 153.402 24.5525 153.665 26.2843H151.894C151.714 25.7 151.159 25.3642 150.395 25.3642C149.701 25.3642 149.188 25.6347 149.188 26.1213Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M156.834 26.1213C156.834 26.5116 157.191 26.7932 157.714 26.912L158.752 27.1283C159.98 27.3885 161.532 27.7786 161.532 29.4233C161.532 31.0692 159.927 32 158.345 32C156.394 32 155.113 30.9933 154.842 29.3047H156.624C156.822 30.1274 157.451 30.5061 158.386 30.5061C158.994 30.5061 159.685 30.3002 159.685 29.6509C159.685 29.142 159.098 28.8928 158.271 28.7094L157.347 28.5141C156.111 28.2435 154.989 27.6372 154.989 26.2298C154.989 24.6277 156.654 23.8806 158.144 23.8806C159.635 23.8806 161.049 24.5525 161.311 26.2843H159.54C159.361 25.7 158.806 25.3642 158.038 25.3642C157.347 25.3642 156.834 25.6347 156.834 26.1213Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.4532 23.6222C86.2298 21.8972 87.371 19.528 87.4976 16.8695H90.2493C89.9776 20.1643 87.5922 22.8687 84.4532 23.6222ZM83.1784 23.4151V16.8695H86.492C86.3489 19.5244 85.0831 21.8512 83.1784 23.4151ZM83.1784 9.06595C85.0451 10.6707 86.3459 13.1686 86.4925 15.8607H83.1784V9.06595ZM90.2678 15.8607H87.4976C87.3685 13.1653 86.1838 10.6352 84.4187 8.85035C87.6542 9.6111 90.0987 12.4415 90.2678 15.8607ZM82.1773 15.8607H78.8673C79.0209 13.1869 80.3177 10.7071 82.1773 9.1029V15.8607ZM82.1773 23.4187C80.2671 21.8548 79.0004 19.5259 78.8648 16.8695H82.1773V23.4187ZM75.1089 16.8695H77.8566C77.9807 19.5253 79.1174 21.8945 80.8935 23.6198C77.7611 22.863 75.3806 20.1606 75.1089 16.8695ZM80.9881 8.8386C79.2045 10.6229 78.0037 13.1575 77.8601 15.8607H75.0909C75.2605 12.4238 77.7301 9.58195 80.9881 8.8386ZM82.6781 7.64185C77.9347 7.64185 74.0758 11.4979 74.0758 16.2402C74.0758 20.98 77.9347 24.8359 82.6781 24.8359C87.4201 24.8359 91.2805 20.98 91.2805 16.2402C91.2805 11.4979 87.4201 7.64185 82.6781 7.64185Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.5043 16.2404C72.5043 10.5887 77.0971 5.99385 82.7426 5.99385C84.8379 5.99385 86.7886 6.29405 88.4131 7.3798V1.53494C86.652 0.852725 84.7404 0.477661 82.7426 0.477661C75.326 0.477661 69.0912 5.63275 67.4277 12.5528V0.477661H54.1419L47.0762 16.3328L40.0188 0.477661H39.241L32.9928 16.889L28.6619 9.5271L24.3734 16.9602L32.9928 32H33.8822L39.63 17.5975L46.4884 32H47.3774L60.651 2.83811V22.7914C60.651 25.8999 56.4424 27.3256 53.5295 23.8848L50.987 29.4874C52.66 31.0401 55.2201 32 57.3584 32C59.2226 32 60.8191 31.7235 62.2019 31.0871C63.3566 30.5337 65.1898 29.1469 65.9337 27.798C67.0809 25.7413 67.3656 23.715 67.4176 19.8867C69.0662 26.8244 75.3115 32 82.7426 32C84.7404 32 86.652 31.6247 88.4131 30.9424V25.0979C86.7886 26.1833 84.8379 26.4835 82.7426 26.4835C77.0971 26.4835 72.5043 21.8888 72.5043 16.2404Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.973 7.16425H27.2409L23.259 0.477661H14.8153L18.6944 7.16425H25.973Z"
        fill="#fff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.807 9.31325H17.2045L12.9035 1.9104L8.60222 9.31325H0L4.30097 16.7163L0 24.1192H8.60222L12.9035 31.5223L17.2045 24.1192H25.807L21.5058 16.7163L25.807 9.31325Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
