<template>
  <BaseInputItem :item="item">
    <PublicFilesUpload :initialFiles="data || []" @update:value="(value) => (data = value)" />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem } from '@/components/form-builder/types'
import { PublicFilesUpload } from '@/public/components/ui'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData } from '../../services/context'

const props = defineProps<{ item: FormItem }>()

const data = useItemData<{ id: string; url: string }[]>(props.item.id)
</script>
