<template>
  <div>
    <div class="text-black text-opacity-88 text-sm font-normal my-4">
      <span class="text-[#FF7875]" v-if="required">*</span> {{ label }}
    </div>

    <div v-for="(day, index) in sortedSubEvents" :key="index">
      <div
        class="rounded-md bg-[#F5F5F5] px-4 py-2 mt-6 mb-4 text-black text-opacity-88 text-xl font-semibold"
      >
        <span v-if="day.day?.start">
          {{ dayjs(day.day.start).format('dddd, DD MMM YYYY') }}
        </span>
        <span v-if="day.day.end && day.day.end != day.day.start">
          - {{ dayjs(day.day.end).format('dddd, DD MMM YYYY') }}
        </span>
      </div>

      <div v-for="subEvent in day.events" :key="subEvent.id">
        <label class="flex my-4 space-x-2">
          <GumCheckbox
            :value="value && value.includes(subEvent.id)"
            @update:value="(checked) => handleEventStateChange(subEvent.id, checked)"
          />

          <div>
            <span class="text-almost-black text-opacity-88 text-sm font-semibold">
              {{ subEvent.name }}
            </span>
            <div class="flex text-almost-black text-opacity-60 text-sm font-normal space-x-1">
              <GumLocalTime
                :time="subEvent.startDate"
                format=" D MMM YYYY "
                :tooltip="false"
                v-if="subEvent.startDate"
              />
              <span v-if="subEvent.startTime"> • </span>
              <span v-if="subEvent.startTime">{{ subEvent.startTime }}</span>
              <span v-if="subEvent.endTime"> - </span>
              <span v-if="subEvent.endTime">{{ subEvent.endTime }}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { GumLocalTime, GumCheckbox } from '@/components/ui'
import { getProgram, type SubEvent } from './getEventProgram.gql'
import dayjs from 'dayjs'

const props = defineProps<{
  eventId: string
  value: string[] | null | undefined
  placeholder?: string
  label?: string
  required?: boolean
  filterById?: string[]
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string[] | undefined | null): void
}>()

const { subEvents } = getProgram(props.eventId)

const filteredSubEvents = computed(() => {
  if (props.filterById && props.filterById.length > 0) {
    return subEvents.value.filter((x) => props.filterById?.includes(x.id))
  }
  return subEvents.value
})

const handleEventStateChange = (subEventId: string, selected: boolean) => {
  if (selected) {
    emit('update:value', [...(props.value || []), subEventId])
  } else {
    emit(
      'update:value',
      (props.value || []).filter((id) => id !== subEventId)
    )
  }
}

const sortedSubEvents = computed(() => {
  let daysMap = filteredSubEvents.value.map((subEvent: SubEvent) => {
    return { start: subEvent.startDate, end: subEvent.endDate }
  })

  const unique = (
    array: {
      start: string
      end: string
    }[],
    encoder = JSON.stringify,
    decoder = JSON.parse
  ) => [...new Set(array.map((item) => encoder(item)))].map((item) => decoder(item))

  let days = unique(daysMap).sort((a, b) => a.start.localeCompare(b.start))

  return days.map((day) => {
    let events = Array<SubEvent>()
    filteredSubEvents.value.forEach((subEvent: SubEvent) => {
      if (subEvent.startDate == day.start && subEvent.endDate == day.end) {
        events.push(subEvent)
      }
    })
    return { day: day, events: events }
  })
})
</script>
