import './assets/main.less'

import dayjs from 'dayjs/esm'
import DayJSTimezonePlugin from 'dayjs/esm/plugin/timezone'
import DayJSUtcPlugin from 'dayjs/esm/plugin/utc'

dayjs.extend(DayJSTimezonePlugin)
dayjs.extend(DayJSUtcPlugin)

import { createApp } from 'vue'
import App from './public/App.vue'
import { initSentry } from './services/sentry'

const app = createApp(App)

initSentry(app, { replies: true })

app.mount('#app')
