import type {
  GetAllCountriesForPublicCountriesSelectQuery,
  GetAllCountriesForPublicCountriesSelectQueryVariables
} from './getAllCountries.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'

const query = gql`
  query GetAllCountriesForPublicCountriesSelect {
    countries(pagination: { items: 500 }, sort: { field: NAME, direction: ASC }) {
      collection {
        value: id
        label: name
      }
      meta {
        pages
      }
    }
  }
`

export const getAllCountries = () => {
  const { result, loading } = useQuery<
    GetAllCountriesForPublicCountriesSelectQuery,
    GetAllCountriesForPublicCountriesSelectQueryVariables
  >(query, {}, { fetchPolicy: 'cache-and-network' })
  const meta = computed(() => result.value?.countries.meta)
  const countries = computed(() => result.value?.countries.collection || [])
  watch(meta, () => {
    if (meta.value?.pages && meta.value.pages > 1) {
      throw "There are more than 500 countries and we're not fetching all of them"
    }
  })

  return { loading, countries }
}
