import type {
  GetAllTitlesForPublicTitleSelectQuery,
  GetAllTitlesForPublicTitleSelectQueryVariables
} from './getAllTitles.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 500

const query = gql`
  query GetAllTitlesForPublicTitleSelect($ids: [ID!], $items: Int) {
    personTitles(ids: $ids, pagination: { items: $items }, sort: { field: TITLE, direction: ASC }) {
      collection {
        value: id
        label: title
      }
      meta {
        pages
      }
    }
  }
`

export const getAllTitles = ({ ids }: { ids?: string[] } = {}) => {
  const { result, loading, refetch } = useQuery<
    GetAllTitlesForPublicTitleSelectQuery,
    GetAllTitlesForPublicTitleSelectQueryVariables
  >(query, { items: FETCH_SIZE, ids })

  const titles = computed(() => result.value?.personTitles.collection || [])

  return { loading, titles, refetch }
}
