import type {
  GetPublicEventGroupsForSelectQuery,
  GetPublicEventGroupsForSelectQueryVariables
} from './getAllEventGroupsForSelect.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPublicEventGroupsForSelect($eventId: ID!) {
    event(id: $eventId) {
      id
      groups {
        id
        name
      }
    }
  }
`

export const getEventCapacities = (eventId: string) => {
  const { loading, result } = useQuery<
    GetPublicEventGroupsForSelectQuery,
    GetPublicEventGroupsForSelectQueryVariables
  >(query, { eventId })

  const groups = computed(() => result.value?.event?.groups || [])

  return { loading, groups }
}
