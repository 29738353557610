<template>
  <GumSkeleton v-if="loading" :title="false" />
  <GumCheckboxGroup
    v-else
    :value="value || []"
    @update:value="(value) => handleSelect(value)"
    :options="groups"
    direction="vertical"
  />
</template>

<script setup lang="ts">
import { GumCheckboxGroup, GumSkeleton } from '@/components/ui'
import { getAllEventGroups } from './getAllEventGroups.gql'

const props = defineProps<{
  eventId: string
  value: string[] | null | undefined
}>()
const emit = defineEmits<{
  (e: 'update:value', value: string[] | undefined): void
}>()

const { loading, groups } = getAllEventGroups(props.eventId)

const handleSelect = (value?: string[]) => {
  emit('update:value', value)
}
</script>
