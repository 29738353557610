<template>
  <GumSelect
    :value="value"
    @update:value="(value) => emit('update:value', value)"
    :options="options"
    :loading="loading"
    :placeholder="placeholder"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { GumSelect } from '@/components/ui'
import { getPublicEventTransportLocationsSelect } from './getTransportLocations.gql'

const props = defineProps<{
  eventId: string
  value: string | null | undefined
  placeholder?: string
}>()
const emit = defineEmits<{
  (e: 'update:value', value: string | undefined | null): void
}>()

const { loading, transportLocations } = getPublicEventTransportLocationsSelect(props.eventId)

const options = computed(() =>
  transportLocations.value.map((capacity) => ({ value: capacity.id, label: capacity.name }))
)
</script>
