import type {
  GetPublicLodgesQuery,
  GetPublicLodgesQueryVariables
} from './getPublicLodges.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPublicLodges($eventId: ID!) {
    event(id: $eventId) {
      id
      lodges {
        id
        name
        visibleInForms
      }
    }
  }
`

export const getPublicLodges = (eventId: string) => {
  const { loading, result } = useQuery<GetPublicLodgesQuery, GetPublicLodgesQueryVariables>(query, {
    eventId
  })

  const allLodges = computed(() => result.value?.event?.lodges || [])

  const lodges = computed(() => allLodges.value.filter((x) => x.visibleInForms))

  return { loading, lodges }
}
