<template>
  <GumSelect
    :value="value"
    @update:value="(value) => emit('update:value', value)"
    :options="countries"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import { GumSelect } from '@/components/ui'
import { getAllCountries } from './getAllCountries.gql'

defineProps<{ value: string | null | undefined }>()

const emit = defineEmits<{
  (e: 'update:value', value: string | undefined | null): void
}>()

const { loading, countries } = getAllCountries()
</script>
