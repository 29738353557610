<template>
  <div
    v-if="hasHeaderImage"
    class="relative bg-white flex flex-col justify-end rounded-t"
    :class="{ 'h-60': !!form.header }"
  >
    <ImgixImage
      v-if="form.header"
      :src="form.header.url"
      :width="704"
      :height="240"
      class="absolute top-0 left-0 w-full h-full object-cover z-10"
    />

    <div
      class="relative p-4 pt-16 z-20"
      style="background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)"
    >
      <div
        class="!text-white !block text-3xl form-title font-semibold m-0"
        v-html="form.title"
      ></div>
    </div>
  </div>

  <GumPanel :class="{ '!rounded-t-none': hasHeaderImage }">
    <div
      v-if="!hasHeaderImage"
      class="pb-4 !block text-3xl form-title font-semibold m-0"
      v-html="form.title"
    ></div>

    <!-- Closed form -->
    <p v-if="form.status === FormStatusEnum.Closed" v-html="form.closedMessageBody" />
    <div v-else>
      <!-- Open form -->
      <GumSkeleton v-if="!session && (startSessionLoading || getSessionLoading)" />
      <div v-else-if="session">
        <!-- Resume session screen -->
        <FormResumeConfirmation
          v-if="showResumeScreen"
          :session="session"
          @confirm="resumeSessionConfirmed = true"
        />
        <!-- Session -->
        <FormSession
          v-else
          :session="session"
          :buttonLabel="form.submitButtonLabel"
          :successMessage="form.successMessageBody"
        />
      </div>
    </div>
  </GumPanel>
</template>

<script setup lang="ts">
import { GumPanel, GumSkeleton, ImgixImage } from '@/components/ui'
import { FormStatusEnum } from '@/graphql/public'
import type { Form } from '../data/getPublicForm.gql'
import { booleanParam, stringParam } from '@/services/utils/current-query-params'
import { useStartFormSessionMutation } from '../data/useStartFormSessionMutation.gql'
import { getFormSession } from '../data/getFormSession.gql'
import { useAction } from '@/services/forms'
import FormSession from './FormSession.vue'
import { computed, provide, watch, ref } from 'vue'
import { SHARED_CONTEXT } from '../services/context'
import FormResumeConfirmation from './FormResumeConfirmation.vue'

const props = defineProps<{
  form: Form
  slug: string
}>()

provide(SHARED_CONTEXT, { eventId: props.form.event.id })

const sessionId = stringParam('session')
const preview = booleanParam('preview', false)

const { session, loading: getSessionLoading, getSession } = getFormSession()

const resumeSessionConfirmed = ref(false)
const showResumeScreen = computed(
  () =>
    !resumeSessionConfirmed.value &&
    (session.value?.submittedFirstName || session.value?.submittedLastName)
)
const hasHeaderImage = computed(() => !!props.form.header)

watch(session, () => {
  if (session.value?.preview && !preview) {
    // Redirect to the preview session
    const builder = new URLSearchParams(window.location.search)
    builder.append('preview', 'true')

    window.location.search = builder.toString()
  }
})

//
// Handles first visit by creating a session and redirecting to the new session
//
const { loading: startSessionLoading, startFormSession } = useStartFormSessionMutation()
const { perform: startSession } = useAction({
  action: () => startFormSession({ slug: props.slug, preview }),
  onSuccess: (result) => {
    const builder = new URLSearchParams(window.location.search)
    builder.append('session', result.formSession!.id)

    window.location.search = builder.toString()
  }
})

if (!sessionId) {
  startSession()
} else {
  // Get the session
  getSession(sessionId)
}
</script>

<style lang="less">
.form-title {
  p {
    margin: 0px;
    padding: 0px;
  }
}
</style>
