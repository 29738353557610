import type {
  StartFormSessionMutation,
  StartFormSessionMutationVariables
} from './useStartFormSessionMutation.gql.generated'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'

const query = gql`
  mutation StartFormSession($slug: String!, $preview: Boolean = false) {
    startFormSession(input: { slug: $slug, preview: $preview }) {
      success
      errors {
        messages
        path
      }
      formSession {
        id
        formRows
      }
    }
  }
`

export const useStartFormSessionMutation = () => {
  const { loading, mutate } = useMutation<
    StartFormSessionMutation,
    StartFormSessionMutationVariables
  >(query)

  const startFormSession = async ({
    slug,
    preview = false
  }: {
    slug: string
    preview?: boolean
  }) => {
    const result = await mutate({ slug, preview })

    return {
      success: result?.data?.startFormSession?.success,
      errors: result?.data?.startFormSession?.errors,
      formSession: result?.data?.startFormSession?.formSession
    }
  }

  return { loading, startFormSession }
}
