<template>
  <GumSelect
    :value="value"
    @update:value="(value) => emit('update:value', value)"
    :options="roomTypes"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import { GumSelect } from '@/components/ui'
import { computed, watch } from 'vue'
import { getPublicRoomTypesOfLodge } from './getPublicRoomTypes.gql'

const props = defineProps<{
  value: string | null | undefined
  lodgeId: string
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string | undefined | null): void
}>()

const { loading, rooms, refetch } = getPublicRoomTypesOfLodge(props.lodgeId)

const roomTypes = computed(() =>
  rooms.value.map((room) => {
    return { value: room.id, label: room.roomType.name }
  })
)
// Refetch options when lodge changes
watch(props, () => {
  refetch({ lodgeId: props.lodgeId })
})
</script>
