<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#E6F4FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.9993 26.667C36.316 26.667 33.3327 29.6503 33.3327 33.3337C33.3327 37.017 36.316 40.0003 39.9993 40.0003C43.6827 40.0003 46.666 37.017 46.666 33.3337C46.666 29.6503 43.6827 26.667 39.9993 26.667ZM43.3327 33.3337C43.3327 31.5003 41.8327 30.0003 39.9993 30.0003C38.166 30.0003 36.666 31.5003 36.666 33.3337C36.666 35.167 38.166 36.667 39.9993 36.667C41.8327 36.667 43.3327 35.167 43.3327 33.3337ZM49.9993 50.0003C49.666 48.817 44.4993 46.667 39.9993 46.667C35.516 46.667 30.3827 48.8003 29.9993 50.0003H49.9993ZM26.666 50.0003C26.666 45.567 35.5493 43.3337 39.9993 43.3337C44.4493 43.3337 53.3327 45.567 53.3327 50.0003V53.3337H26.666V50.0003Z"
      fill="#0958D9"
    />
  </svg>
</template>
