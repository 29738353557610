import type {
  GetFormSessionQuery,
  GetFormSessionQueryVariables
} from './getFormSession.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetFormSession($sessionId: String!) {
    formSession(sessionId: $sessionId) {
      id
      data
      parsedData {
        id
        label
        kind
        displayValue
      }
      submittedFirstName
      submittedLastName
      preview
      formRows
      createdAt
    }
  }
`

export type FormSession = NonNullable<GetFormSessionQuery['formSession']>
export type ParsedData = FormSession['parsedData']

export const getFormSession = () => {
  const { loading, load, result } = useLazyQuery<GetFormSessionQuery, GetFormSessionQueryVariables>(
    query
  )

  const getSession = (sessionId: string) => load(undefined, { sessionId })
  const session = computed(() => result.value?.formSession)

  return { loading, getSession, session }
}
