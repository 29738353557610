<template>
  <EnvironmentBanner />
  <FormShowPage />
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { ApolloClient, InMemoryCache, from } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { logout } from '@/services/auth/auth'
import { notifyGenericError } from '@/services/notify'
import FormShowPage from './pages/Forms/ShowPage.vue'
import { EnvironmentBanner } from '@/components/ui'

// Setup Apollo

const httpLink = new BatchHttpLink({
  // You should use an absolute URL here
  uri: `${import.meta.env.VITE_APP_URL}/public/graphql`,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 200 // Wait no more than 20ms after first batched operation
})

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (networkError) {
    // @ts-ignore It's a Apollo quirk
    switch (networkError['statusCode']) {
      case 401:
        // The session has expired. Logout forcefully so the user is sent to the login
        return logout()
      default:
        // Something else
        notifyGenericError()
        console.error({ networkError, operation })
    }
  }

  if (graphQLErrors) {
    console.group('GraphQL Errors')
    graphQLErrors.forEach((error) => console.error(error))
    console.groupEnd()
  }
})

const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network'
    }
  }
})

provide(DefaultApolloClient, apolloClient)
</script>
