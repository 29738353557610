<template>
  <BaseInputItem :item="item">
    <GumSelect v-model:value="data" :placeholder="item.placeholder" :options="options" />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem, FormItemSelect } from '@/components/form-builder/types'
import { GumSelect } from '@/components/ui'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData } from '../../services/context'
import { computed } from 'vue'

const props = defineProps<{ item: FormItem }>()

const data = useItemData<string | null>(props.item.id)

const options = computed(() =>
  (props.item as FormItemSelect).options.map((option) => ({ label: option, value: option }))
)
</script>
