<template>
  <BaseInputItem :item="item">
    <PublicCapacitySelect :eventId="eventId" v-model:value="data" :placeholder="item.placeholder" />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem } from '@/components/form-builder/types'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData, useEventId } from '../../services/context'
import { PublicCapacitySelect } from '@/public/components/ui'

const props = defineProps<{ item: FormItem }>()
const eventId = useEventId()

const data = useItemData<string | null>(props.item.id)
</script>
