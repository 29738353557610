<template>
  <GumCheckboxGroup
    :value="value || []"
    @update:value="(value) => emit('update:value', value)"
    :options="subEvents"
    :loading="loading"
    :placeholder="placeholder"
    direction="vertical"
  />
</template>

<script setup lang="ts">
import { GumCheckboxGroup } from '@/components/ui'
import { getSubEvents } from './getEventSubEvents.gql'

const props = defineProps<{
  eventId: string
  value: string[] | null | undefined
  placeholder?: string
}>()
const emit = defineEmits<{
  (e: 'update:value', value: string[] | undefined | null): void
}>()

const { loading, subEvents } = getSubEvents(props.eventId)
</script>
