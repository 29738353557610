import type {
  GetPublicRoomTypesQuery,
  GetPublicRoomTypesQueryVariables
} from './getPublicRoomTypes.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPublicRoomTypes($lodgeId: ID!) {
    eventLodge(id: $lodgeId) {
      id
      roomQuantities {
        id
        roomType {
          id
          name
        }
        sharingAllowed
      }
    }
  }
`

export const getPublicRoomTypesOfLodge = (lodgeId: string) => {
  const { loading, result, refetch } = useQuery<
    GetPublicRoomTypesQuery,
    GetPublicRoomTypesQueryVariables
  >(query, { lodgeId })

  const rooms = computed(() => result.value?.eventLodge?.roomQuantities || [])

  return { loading, rooms, refetch }
}
