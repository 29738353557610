import type { FormItem, FormRow } from '@/components/form-builder/types'

export const flatten = (rows: FormRow[] | FormItem[]): FormItem[] => {
  let final: FormItem[] = []

  for (const row of rows) {
    if (row.kind === 'page-break' || row.kind === 'paragraph') {
      // Skip
    } else if ('children' in row) {
      final = final.concat(flatten(row.children))
    } else {
      final.push(row)
    }
  }

  return final
}
