import type {
  GetPublicEventTransportLocationsSelectQuery,
  GetPublicEventTransportLocationsSelectQueryVariables
} from './getTransportLocations.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPublicEventTransportLocationsSelect($id: ID!) {
    event(id: $id) {
      id
      transportLocations {
        id
        name
      }
    }
  }
`

export type TransportLocation = NonNullable<
  NonNullable<GetPublicEventTransportLocationsSelectQuery>['event']
>['transportLocations'][0]

export const getPublicEventTransportLocationsSelect = (id: string) => {
  const { result, loading, refetch } = useQuery<
    GetPublicEventTransportLocationsSelectQuery,
    GetPublicEventTransportLocationsSelectQueryVariables
  >(query, { id })

  const transportLocations = computed(() => result.value?.event?.transportLocations || [])

  return { transportLocations, loading, refetch }
}
