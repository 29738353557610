import { computed, provide, ref } from 'vue'
import type { FormData } from './types'
import { dataInjectionKey } from './key'
import type { Errors } from '@/types/errors'
import { validateItems } from './validation'
import type { FormRow } from '@/components/form-builder/types'
import { uid } from 'uid'
import { useAction } from '@/services/forms'
import { useSubmitFormSessionDataMutation } from '../data/useSubmitFormSessionDataMutation.gql'
import { applyVisibility } from './conditions'
import { devWatchRef } from '@/services/debug'
import type { ParsedData } from '../data/getFormSession.gql'
import { flatten } from './flatten'

type UseFormArguments = {
  sessionId: string
  initialData: FormData
  initialParsedData: ParsedData
  formRows: FormRow[]
}

export const useForm = ({
  sessionId,
  initialData,
  initialParsedData,
  formRows
}: UseFormArguments) => {
  const data = ref<FormData>({ ...initialData })
  const parsedData = ref<ParsedData>([...initialParsedData])
  const errors = ref<Errors>([])

  //
  // Data submission
  //
  const { loading: submitting, submitFormSessionData } = useSubmitFormSessionDataMutation()
  const { perform: submitData } = useAction({
    action: ({ lastStep = false }: { lastStep?: boolean }) =>
      submitFormSessionData({ sessionId: sessionId, data: data.value, lastStep }),
    onSuccess: ({ formSession }) => {
      parsedData.value = formSession?.parsedData || []
    }
  })

  // Provide the data down the tree for easy access
  provide(dataInjectionKey, data)

  //
  // Steps management
  //
  const steps = (() => {
    const final: { id: string; rows: FormRow[] }[] = []
    const stack: FormRow[] = []
    const createStep = (rows: FormRow[]) => ({ id: uid(), rows: [...rows] })

    if (!Array.isArray(formRows)) {
      throw 'Form rows are not an array'
    }

    formRows.forEach((row) => {
      if (row.kind === 'page-break') {
        final.push(createStep(stack))
        stack.splice(0, stack.length)
      } else {
        stack.push(row)
      }
    })

    if (stack.length > 0) {
      final.push(createStep(stack))
    }

    return final
  })()

  const visibleSteps = computed(() =>
    steps
      .map((step) => ({ id: step.id, rows: applyVisibility(step.rows, data.value) }))
      .filter((step) => step.rows.length > 0)
      // Add a summary step at the end
      .concat({ id: 'summary', rows: [] })
  )

  const visibleParsedData = computed(() => {
    const visibleQuestionInFlatArray = flatten(visibleSteps.value.flatMap((step) => step.rows))
    const visibleQuestionsMap = Object.fromEntries(
      visibleQuestionInFlatArray.map((item) => [item.id, item])
    )

    return parsedData.value.filter((item) => !!visibleQuestionsMap[item.id])
  })

  const currentStepVisibleRows = computed(() => currentStep.value.rows)

  const completed = ref<boolean>(false)
  const currentStepIndex = ref<number>(0)
  const currentStep = computed(() => visibleSteps.value[currentStepIndex.value])
  const isFirstStep = computed(() => currentStepIndex.value === 0)
  const isLastStep = computed(() => currentStepIndex.value === visibleSteps.value.length - 1)

  //
  // Api
  //
  const goBack = async () => {
    if (isFirstStep.value || completed.value) {
      return
    }

    currentStepIndex.value = currentStepIndex.value - 1
  }

  const goForward = async () => {
    if (!validateStep()) {
      return
    }

    await submitData({ lastStep: isLastStep.value })

    if (isLastStep.value) {
      completed.value = true
      return
    }

    currentStepIndex.value = currentStepIndex.value + 1
  }

  const validateStep = (): boolean => {
    errors.value = validateItems(currentStepVisibleRows.value, data.value)

    return errors.value.length === 0
  }

  //
  // Debug
  //
  devWatchRef(data, 'Data')

  return {
    data,
    parsedData: visibleParsedData,
    errors,
    completed,
    isFirstStep,
    isLastStep,
    currentStep,
    submitting,
    api: {
      goBack,
      goForward
    }
  }
}
