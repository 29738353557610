import type {
  SubmitFormSessionDataMutation,
  SubmitFormSessionDataMutationVariables
} from './useSubmitFormSessionDataMutation.gql.generated'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { FormData } from '../services/types'

const query = gql`
  mutation SubmitFormSessionData($sessionId: ID!, $data: JSON!, $lastStep: Boolean = false) {
    submitFormSessionData(input: { sessionId: $sessionId, data: $data, lastStep: $lastStep }) {
      success
      errors {
        messages
        path
      }
      formSession {
        id
        parsedData {
          id
          label
          kind
          displayValue
        }
      }
    }
  }
`

export const useSubmitFormSessionDataMutation = () => {
  const { loading, mutate } = useMutation<
    SubmitFormSessionDataMutation,
    SubmitFormSessionDataMutationVariables
  >(query)

  const submitFormSessionData = async (variables: {
    sessionId: string
    data: FormData
    lastStep?: boolean
  }) => {
    const result = await mutate(variables)

    return {
      success: result?.data?.submitFormSessionData?.success,
      errors: result?.data?.submitFormSessionData?.errors,
      formSession: result?.data?.submitFormSessionData?.formSession
    }
  }

  return { loading, submitFormSessionData }
}
