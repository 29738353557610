<template>
  <BaseInputItem :item="item">
    <PublicTitleSelect
      v-model:value="data"
      :filterById="item.useOptionsSubset ? item.optionsSubset : undefined"
    />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem } from '@/components/form-builder/types'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData } from '../../services/context'
import { PublicTitleSelect } from '@/public/components/ui'

const props = defineProps<{ item: FormItem }>()
const data = useItemData<string | null>(props.item.id)
</script>
