<template>
  <BaseInputItem :item="item" noLabel>
    <PublicEventProgram
      :eventId="eventId"
      v-model:value="data"
      :label="item.label"
      :required="item.required"
      :filterById="item.useOptionsSubset ? item.optionsSubset : undefined"
    />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem } from '@/components/form-builder/types'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData, useEventId } from '../../services/context'
import { PublicEventProgram } from '@/public/components/ui'

const props = defineProps<{ item: FormItem }>()
const eventId = useEventId()

const data = useItemData<string[] | null>(props.item.id)
</script>
