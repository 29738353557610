<template>
  <div
    v-if="preview"
    class="w-full flex justify-center border-solid border-x-0 border-b-0 border-t-red-700 z-20"
  >
    <div class="bg-red-700 px-4 pt-1 pb-2 rounded-b text-white">
      This is a preview. Don't share this link with users
      <GumButton type="default" size="middle" class="ml-2" @click="resetSession">
        Reload with latest changes
      </GumButton>
    </div>
  </div>
  <div
    class="min-h-screen relative z-10 flex flex-col justify-between"
    :style="{ background: `${form?.backgroundColor}` }"
  >
    <div class="container max-w-[48rem] pt-14 mb-24">
      <GumPanel v-if="loading"><GumSkeleton /></GumPanel>
      <GumPanel v-else-if="!form"><NotFound /></GumPanel>
      <FormSessionLoader v-else :form="form" :slug="formSlug" />
    </div>
    <FormFooter />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { GumButton, GumPanel, GumSkeleton } from '@/components/ui'
import { getPublicForm } from './data/getPublicForm.gql'
import NotFound from '../../components/layout/NotFound.vue'
import FormSessionLoader from './components/FormSessionLoader.vue'
import { booleanParam } from '@/services/utils/current-query-params'
import FormFooter from './footer/FormFooter.vue'
import { resetSession } from './services/session'

const pathSegments = window.location.pathname.split('/').slice(1)
const formSlug = pathSegments[pathSegments[0] === 'p' ? 1 : 0]

const { loading, form } = getPublicForm(formSlug)

const preview = booleanParam('preview', false)

watch(form, (newForm) => {
  document.title = newForm?.metaTitle || newForm?.plainTitle || 'Page Not Found'
  document
    .querySelector('head meta[name="description"]')
    ?.setAttribute('content', newForm?.metaDescription || 'Registration')
})
</script>
