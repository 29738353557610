<template>
  <GumSelect
    :value="value"
    @update:value="(value) => emit('update:value', value)"
    :options="options"
    :loading="loading"
    :placeholder="placeholder"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { GumSelect } from '@/components/ui'
import { getPublicLodges } from './getPublicLodges.gql'

const props = defineProps<{
  value: string | null | undefined
  valueLabel?: string
  eventId: string
  placeholder?: string
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string | undefined | null): void
}>()

const { loading, lodges } = getPublicLodges(props.eventId)

const options = computed(() =>
  lodges.value.map((lodge) => ({
    value: lodge.id,
    label: lodge.name
  }))
)
</script>
