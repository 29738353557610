<template>
  <AntUpload
    name="file"
    list-type="picture-card"
    v-model:fileList="antFilesList"
    :action="fileAPIUrl"
    :isImageUrl="isImage"
    @change="handleChange"
    :disabled="disabled"
  >
    <div v-if="!disabled">
      <PlusOutlined />
      <div class="ant-upload-text">Upload</div>
    </div>
  </AntUpload>
</template>

<script setup lang="ts">
import AntUpload, {
  type UploadChangeParam,
  type UploadFile,
  type UploadProps
} from 'ant-design-vue/es/upload'
import { ref, watch } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'

const fileAPIUrl = `${import.meta.env.VITE_APP_URL}/public/api/files`

type FileValue = { id: string; url: string }

const props = defineProps<{
  disabled?: boolean
  initialFiles?: FileValue[]
}>()
const emit = defineEmits<{ (e: 'update:value', value: FileValue[]): void }>()

// We determine if the file is an image if it mentions imgix in the URL 🙈
const isImage = (file: UploadFile) => !!file.url && file.url.includes('imgix.net')

// The signed AWS URL contains a query parameter called `response-content-disposition` which contains the filename
const extractFileNameFromUrl = (url: string) => {
  const contentDisposition = new URLSearchParams(new URL(url).search).get(
    'response-content-disposition'
  )

  if (!contentDisposition) {
    return url
  }

  const matches = contentDisposition.match(/filename="(.*)"/)

  if (matches && !!matches[1]) {
    return matches[1]
  }

  return null
}

const antFilesList = ref<UploadProps['fileList']>([
  ...(props.initialFiles?.map((item) => ({
    uid: item.id,
    name: extractFileNameFromUrl(item.url) || 'File',
    url: item.url
  })) || [])
])

watch(antFilesList, () => {
  const newValue = antFilesList.value
    ?.filter(
      (item) => (!item.response && !!item.url) || (item.status === 'done' && !!item.response)
    )
    .map((item) => {
      if (item.response) {
        const serverResponse = item.response as { id: string; url: string }

        return { id: serverResponse.id, url: serverResponse.url }
      }

      return { id: item.uid, url: item.url! }
    })

  emit('update:value', newValue || [])
})

const handleChange = (info: UploadChangeParam) => {
  if (info.file.status === 'done') {
    const serverResponse = info.file.response as { id: string; url: string }

    info.file.url = serverResponse.url
    info.file.uid = serverResponse.id
  }
}
</script>
