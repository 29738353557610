<template>
  <BaseInputItem :item="item">
    <PublicLodgeSelect :eventId="eventId" v-model:value="lodgeId" :placeholder="item.placeholder" />
    <PublicRoomTypeSelect
      v-if="lodgeId"
      :lodgeId="lodgeId"
      v-model:value="roomId"
      :placeholder="item.placeholder"
      class="mt-4"
    />
  </BaseInputItem>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { FormItem } from '@/components/form-builder/types'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData, useEventId } from '../../services/context'
import { PublicLodgeSelect, PublicRoomTypeSelect } from '@/public/components/ui'

const props = defineProps<{ item: FormItem }>()
const eventId = useEventId()

type Answer = {
  lodgeId?: string
  roomId?: string
}
const data = useItemData<Answer | null>(props.item.id)

const lodgeId = computed({
  get() {
    return data.value?.lodgeId
  },
  set(value) {
    data.value = { ...(data.value || {}), lodgeId: value }
  }
})
const roomId = computed({
  get() {
    return data.value?.roomId
  },
  set(value) {
    data.value = { ...(data.value || {}), roomId: value }
  }
})
</script>
