import type { FormItem, FormRow, FormRowColumns } from '@/components/form-builder/types'
import type { Errors } from '@/types/errors'
import type { FormData } from '../services/types'
import { getItemDefinition } from '../items'

function* traverse(
  items: FormRow[] | FormItem[] | FormRowColumns[]
): Generator<FormRow | FormItem> {
  for (let i = 0, len = items.length, item = items[i]; i < len; item = items[++i]) {
    yield item

    if (item.kind === 'columns' || item.kind === 'group') {
      // It has children, traverse them
      yield* traverse(item.children)
    }
  }
}

export const validateItems = (rows: FormRow[], data: FormData): Errors => {
  const errors: Errors = []

  for (const item of traverse(rows)) {
    if (item.kind === 'columns' || item.kind === 'group' || item.kind === 'page-break') continue

    const error = validateItem(item, data)

    if (error) {
      errors.push({ messages: [error], path: item.id })
    }
  }

  return errors
}

const validateItem = (item: FormItem, data: FormData): string | undefined => {
  if (item.required && !data[item.id]) {
    return 'This field is required'
  }

  const definition = getItemDefinition(item.kind)
  if (definition.validate) {
    return definition.validate(data[item.id], item)
  }

  return undefined
}
