import { computed, inject, type InjectionKey } from 'vue'
import { dataInjectionKey } from './key'
import type { FormItemData } from './types'

export const SHARED_CONTEXT = Symbol() as InjectionKey<{ eventId: string }>

export const useEventId = () => {
  const context = inject(SHARED_CONTEXT)

  if (!context) {
    throw new Error('useEventId must be used within a FormProvider')
  }

  return context?.eventId
}

export const useData = () => inject(dataInjectionKey)!
export const useItemData = <T extends FormItemData>(id: string) => {
  const data = useData()

  const fieldData = computed<T | null>({
    get() {
      return data.value[id] as T
    },
    set(value) {
      data.value[id] = value
    }
  })

  return fieldData
}
