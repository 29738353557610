import { watch, type Ref } from 'vue'

// Log only in development mode
export const devLog = (message?: any, ...optionalParams: any[]) => {
  if (import.meta.env.MODE !== 'development') return

  console.log(message, ...optionalParams)
}

export const devLogRef = (ref: Ref<any>, message?: string) => {
  devLog(`[${message}]`, JSON.parse(JSON.stringify(ref.value)))
}

export const devWatchRef = (ref: Ref<any>, message?: string) => {
  devLogRef(ref, `Initial ${message}`)
  watch(ref, () => devLogRef(ref, message), { deep: true })
}
