<template>
  <BaseInputItem :item="item">
    <GumCreditCardInput :maxLength="16" v-model:value="data" :placeholder="item.placeholder" />
  </BaseInputItem>
</template>

<script setup lang="ts">
import type { FormItem } from '@/components/form-builder/types'
import { GumCreditCardInput } from '@/components/ui'
import BaseInputItem from './BaseInputItem.vue'
import { useItemData } from '../../services/context'

const props = defineProps<{ item: FormItem }>()

const data = useItemData<string | null>(props.item.id)
</script>
