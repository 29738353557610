<template>
  <GumSelect
    :value="value"
    @update:value="(value) => emit('update:value', value)"
    :options="titles"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { GumSelect } from '@/components/ui'
import { getAllTitles } from './getAllTitles.gql'

const props = defineProps<{
  filterById?: string[]
  value: string | null | undefined
}>()
const emit = defineEmits<{
  (e: 'update:value', value: string | undefined | null): void
}>()

const { loading, titles, refetch } = getAllTitles({ ids: props.filterById })

watch(
  () => props.filterById,
  () => refetch({ ids: props.filterById })
)
</script>
