<template>
  <p v-if="completed" v-html="successMessage" />
  <GumForm v-else :errors="errors" layout="vertical">
    <FormStep v-if="currentStep.id !== 'summary'" :rows="currentStep.rows" />
    <FormSummary :data="data" :parsedData="parsedData" v-else />

    <div class="flex items-center justify-between pt-6 actions-border mt-4">
      <GumButton
        v-if="!isFirstStep"
        size="middle"
        type="default"
        @click="goBack"
        :loading="submitting"
      >
        Back
      </GumButton>
      <GumButton size="middle" class="ml-auto" @click="goForward" :loading="submitting">
        {{ isLastStep ? `${buttonLabel}` : 'Next' }}
      </GumButton>
    </div>
  </GumForm>
</template>

<script setup lang="ts">
import { GumButton, GumForm } from '@/components/ui'
import type { FormSession } from '../data/getFormSession.gql'
import FormStep from './FormStep.vue'
import { useForm } from '../services/useForm'
import FormSummary from './FormSummary.vue'

const props = defineProps<{
  session: FormSession
  buttonLabel: string
  successMessage: string
}>()

const {
  errors,
  submitting,
  isFirstStep,
  isLastStep,
  completed,
  currentStep,
  data,
  parsedData,
  api: { goBack, goForward }
} = useForm({
  initialData: props.session.data,
  formRows: props.session.formRows,
  sessionId: props.session.id,
  initialParsedData: props.session.parsedData
})
</script>

<style scoped lang="less">
.actions-border {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
