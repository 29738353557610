<template>
  <BaseInputItem :item="item" noLabel>
    <div v-html="(item as FormItemConsent).content" class="break-words" />
    <GumCheckbox v-model:value="data">
      <span v-if="item.required" class="text-[#ff4d4f]">*</span>
      {{ item.label }}
    </GumCheckbox>
  </BaseInputItem>
</template>

<script setup lang="ts">
import { GumCheckbox } from '@/components/ui'
import BaseInputItem from './BaseInputItem.vue'
import type { FormItemConsent } from '@/components/form-builder/types'
import { useItemData } from '../../services/context'

const props = defineProps<{ item: FormItemConsent }>()
const data = useItemData<boolean | null>(props.item.id)
</script>
